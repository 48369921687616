import { Box, Grid } from '@mui/material'
import { graphql, navigate } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { Header, SEO, useI18next } from '../components'

export default (props) => {
  const [value, setValue] = React.useState(0)

  const {} = useTranslation()
  const { t, languages, changeLanguage, language } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const headerLocale = JSON.parse(props.data.headerLocale.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)

  return (
    <>
      <SEO
        title={seoLocale.about.title}
        description={seoLocale.about.description}
        pageLocale={pageLocale}
      />
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box
        container
        component={Grid}
        sx={{
          height: '100vh',
          px: ['20px', '80px'],
          bgcolor: '#095ef7',
          display: 'flex',
          '.succ_text': {
            mt: ['115px', '200px', '230px'],
            color: '#fff',
            '.title': {
              fontSize: ['30px', '52px'],
              fontWeight: 600,
            },
            '.content': {
              fontSize: ['18px', '40px'],
            },
          },
          '.ptimg': {
            mt: ['0px', '0px', '200px'],
          },
          '.btngroup': {
            width: '100%',
            display: 'flex',
            flexDirection: ['column', 'column', 'unset'],
            alignItems: 'center',
            justifyContent: 'center',
            '.btn': {
              cursor: 'pointer',
              fontWeight: 500,
              height: ['38px'],
              lineHeight: ['38px'],
              textAlign: 'center',
              color: '#000000',
              fontSize: ['16px'],
              px: ['35px'],
              borderRadius: ['19px'],
              bgcolor: '#FFF303',
              mb: ['30px'],
              ' &:active': {
                opacity: '0.6',
              },
              '&:first-child': {
                marginInlineEnd: ['0px', '0px', '100px'],
              },
            },
          },
        }}
      >
        <Grid item sm={12} md={5}>
          <div className='succ_text'>
            <div className='title'>Thank You.</div>
            <div className='content'>We will contact you soon</div>
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/enquiresuccess.png'
            alt='Enquire Success'
            className='ptimg'
          />
          {/* <StaticImage
            src='../../static/images/enquiresuccess.png'
            alt='Enquire Success'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='ptimg'
          /> */}
        </Grid>
        <Grid item className='btngroup'>
          <div className='btn' onClick={() => navigate('/')}>
            Return to home page
          </div>
          <div className='btn' onClick={() => navigate('/service/whatWeDo')}>
            Know more about us
          </div>
        </Grid>
      </Box>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["about"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
